import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { Box, styled, useMediaQuery } from "@mui/material";
import { fetchLanguages } from "api/features/Auth/authApi";
import Select, { IOptions } from "components/molecules/Select";
import { Language } from "models";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";

import { theme } from "theme";

import PageWithLogo from "../PageWithLogo";

const StyledWrapper = styled(Box)`
	h4 {
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		text-align: start;
		line-height: 40px;
		margin: 32px 0 24px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		text-align: start;
		line-height: 28px;
	}

	a {
		color: ${props => props.theme.palette.primary.main};
	}
`;

const filterLanguages = (countryId?: string): string[] => {
	switch (countryId) {
		case "ca": {
			return ["en", "fr"];
		}
		case "ch": {
			return ["de", "en", "fr", "it"];
		}
		case "es": {
			return ["es", "en"];
		}
		case "gr": {
			return ["el", "en"];
		}
		case "fr": {
			return ["fr", "en"];
		}
		case "de": {
			return ["de", "en"];
		}
		case "it": {
			return ["it"];
		}
		default: {
			return ["en"];
		}
	}
};

interface Props {
	children: ReactNode;
	dict?: { [key in string]: string };
}

const InfoPageWrapper: FC<Props> = ({ children, dict }) => {
	const { i18n, t } = useTranslation();
	const { defaultLanguage, countryId } = useParams();
	console.log(countryId); // eslint-disable-line no-console
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const language = useMemo(() => {
		const availableLanguages = filterLanguages(countryId);
		return availableLanguages
			? availableLanguages.find(i => i === defaultLanguage?.toLowerCase()) || availableLanguages[0]
			: "en";
	}, [countryId, defaultLanguage]);
	const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
	const [languageOptions, setLanguageOptions] = useState<IOptions[]>([]);
	const [text, setText] = useState("");

	const getLanguageOptions = useCallback(async () => {
		const apiData: Language[] = await fetchLanguages();
		setLanguageOptions(apiData.map(language => ({ value: language.code, label: language.name })));
		return;
	}, []);

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
	}, [i18n, selectedLanguage]);

	useEffect(() => {
		getLanguageOptions();
	}, [getLanguageOptions]);

	useEffect(() => {
		if (dict) {
			fetch(dict[selectedLanguage]).then(r => r.text().then(t => setText(t)));
		}
	}, [dict, selectedLanguage]);

	const options = useMemo(
		() => languageOptions.filter(l => filterLanguages(countryId).includes(l.value as string)),
		[countryId, languageOptions]
	);

	return (
		<PageWithLogo
			pushLogoLeft={options.length > 1}
			rightBlock={
				options.length > 1 &&
				isDesktop && (
					<Select
						fieldLabel={t("dropdowns.language.label")}
						value={selectedLanguage}
						defaultValue={options[0]}
						onChange={setSelectedLanguage}
						options={options}
						variant="outlined"
					/>
				)
			}
		>
			<Box width="100%" height="100%" display="flex" bgcolor="customShades.light2" overflow="auto">
				<Box
					margin="auto"
					display="flex"
					flexDirection="column"
					maxWidth="912px"
					width="100%"
					dir={defaultLanguage === "he" ? "rtl" : "ltr"}
					height="100%"
					bgcolor="common.white"
					padding={isDesktop ? "64px 48px 48px" : "24px 48px 48px"}
					overflow="auto"
					gap={4}
				>
					{options.length > 1 && !isDesktop && (
						<Select
							fieldLabel={t("dropdowns.language.label")}
							value={selectedLanguage}
							defaultValue={options[0]}
							onChange={setSelectedLanguage}
							options={options}
							variant="outlined"
						/>
					)}
					{children}
					{dict && (
						<StyledWrapper color="common.black">
							<ReactMarkdown>{text}</ReactMarkdown>
						</StyledWrapper>
					)}
				</Box>
			</Box>
		</PageWithLogo>
	);
};

export default InfoPageWrapper;
