import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import languageCS from "./locales/cs/translations.json";
import languageDE from "./locales/de/translations.json";
import languageEL from "./locales/el/translations.json";
import languageEN from "./locales/en/translations.json";
import languageES from "./locales/es/translations.json";
import languageFR from "./locales/fr/translations.json";
import languageHE from "./locales/he/translations.json";
import languageIT from "./locales/it/translations.json";
import languageNL from "./locales/nl/translations.json";
import languagePT from "./locales/pt/translations.json";
import languageSK from "./locales/sk/translations.json";

i18n.use(initReactI18next).init({
	fallbackLng: "en",
	lng: "en",
	resources: {
		en: {
			translations: languageEN
		},
		el: {
			translations: languageEL
		},
		cs: {
			translations: languageCS
		},
		es: {
			translations: languageES
		},
		pt: {
			translations: languagePT
		},
		fr: {
			translations: languageFR
		},
		he: {
			translations: languageHE
		},
		it: {
			translations: languageIT
		},
		nl: {
			translations: languageNL
		},
		de: {
			translations: languageDE
		},
		sk: {
			translations: languageSK
		}
	},
	ns: ["translations"],
	defaultNS: "translations"
});

i18n.languages = ["cs", "de", "en", "el", "es", "pt", "fr", "he", "sk", "nl"];

export default i18n;
