import {
	Austria,
	Belgium,
	Canada,
	CzechRepublic,
	Denmark,
	Finland,
	France,
	Germany,
	Greece,
	Ireland,
	Israel,
	Italy,
	Norway,
	Portugal,
	Slovakia,
	Spain,
	Sweden,
	Switzerland,
	TheNetherlands,
	UnitedKingdom,
	UnitedStates
} from "assets";

export const countries = [
	{
		id: "AT",
		name: "Austria",
		countryCode: "AT",
		FlagSVG: Austria,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "BE",
		name: "Belgium",
		countryCode: "BE",
		FlagSVG: Belgium,
		preferredLanguage: [],
		defaultLanguage: "fr"
	},
	{
		id: "CA",
		name: "Canada",
		countryCode: "CA",
		FlagSVG: Canada,
		preferredLanguage: ["en", "fr"],
		defaultLanguage: "en"
	},
	{
		id: "CZ",
		name: "Czech Republic",
		countryCode: "CZ",
		FlagSVG: CzechRepublic,
		preferredLanguage: [],
		defaultLanguage: "cs"
	},
	{
		id: "DK",
		name: "Denmark",
		countryCode: "DK",
		FlagSVG: Denmark,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "FI",
		name: "Finland",
		countryCode: "FI",
		FlagSVG: Finland,
		preferredLanguage: ["en", "fr"],
		defaultLanguage: "en"
	},
	{
		id: "FR",
		name: "France",
		countryCode: "FR",
		FlagSVG: France,
		preferredLanguage: ["en", "fr"],
		defaultLanguage: "fr"
	},
	{
		id: "DE",
		name: "Germany",
		countryCode: "DE",
		FlagSVG: Germany,
		preferredLanguage: ["de", "en"],
		defaultLanguage: "de"
	},
	{
		id: "GR",
		name: "Greece",
		countryCode: "GR",
		FlagSVG: Greece,
		preferredLanguage: [],
		defaultLanguage: "el"
	},
	{
		id: "IE",
		name: "Ireland",
		countryCode: "IE",
		FlagSVG: Ireland,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "IL",
		name: "Israel",
		countryCode: "IL",
		FlagSVG: Israel,
		preferredLanguage: [],
		defaultLanguage: "he"
	},
	{
		id: "IT",
		name: "Italy",
		countryCode: "IT",
		FlagSVG: Italy,
		preferredLanguage: [],
		defaultLanguage: "it"
	},
	{
		id: "NO",
		name: "Norway",
		countryCode: "NO",
		FlagSVG: Norway,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "PT",
		name: "Portugal",
		countryCode: "PT",
		FlagSVG: Portugal,
		preferredLanguage: [],
		defaultLanguage: "pt"
	},
	{
		id: "SK",
		name: "Slovakia",
		countryCode: "SK",
		FlagSVG: Slovakia,
		preferredLanguage: [],
		defaultLanguage: "sk"
	},
	{
		id: "ES",
		name: "Spain",
		countryCode: "ES",
		FlagSVG: Spain,
		preferredLanguage: [],
		defaultLanguage: "es"
	},
	{
		id: "SE",
		name: "Sweden",
		countryCode: "SE",
		FlagSVG: Sweden,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "CH",
		name: "Switzerland",
		countryCode: "CH",
		FlagSVG: Switzerland,
		preferredLanguage: ["en", "fr", "it", "de"],
		defaultLanguage: "de"
	},
	{
		id: "NL",
		name: "The Netherlands",
		countryCode: "NL",
		FlagSVG: TheNetherlands,
		preferredLanguage: [],
		defaultLanguage: "nl"
	},
	{
		id: "GB-UKM",
		name: "United Kingdom",
		countryCode: "GB",
		FlagSVG: UnitedKingdom,
		preferredLanguage: [],
		defaultLanguage: "en"
	},
	{
		id: "US",
		name: "United States",
		countryCode: "US",
		FlagSVG: UnitedStates,
		preferredLanguage: [],
		defaultLanguage: "en"
	}
];
