import { useCallback, useContext, useEffect, useState } from "react";

import { checkIfCRMUserExists, signUpRequest } from "api/features/Auth/authApi";
import { CRMUserRequestDto, CRMUserResponseDto } from "api/services/auth";
import EmailVerification from "components/organisms/SignUp/EmailVerification";
import FormOne from "components/organisms/SignUpHCP/FormOne";
import { IFormOne } from "components/organisms/SignUpHCP/FormOne/FormOne";
import FormTwo from "components/organisms/SignUpHCP/FormTwo";
import { IFormTwo } from "components/organisms/SignUpHCP/FormTwo/FormTwo";
import FormTwoCRM from "components/organisms/SignUpHCP/FormTwoCRM";
import FormTwoDE from "components/organisms/SignUpHCP/FormTwoDE";
import FormTwoUS from "components/organisms/SignUpHCP/FormTwoUS";

import { AppContext } from "contexts/app/AppProvider";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";

const SignUpHCP = () => {
	const { i18n } = useTranslation();
	const { setIsRTL } = useContext(AppContext);
	const { countryId, defaultLanguage } = usePrefix();
	const [CRMFirstName, setCRMFirstName] = useState("");
	const [CRMLastName, setCRMLastName] = useState("");
	const [activeStep, setActiveStep] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formOneState, setFormOneState] = useState<IFormOne>({
		username: "",
		password: "",
		confirmPassword: "",
		termsAndPrivacy: false,
		emailCommunication: false
	});

	useEffect(() => {
		i18n.changeLanguage(defaultLanguage);
		if (defaultLanguage == "he") {
			setIsRTL(true);
		}
	}, [defaultLanguage, i18n, setIsRTL]);

	const submitAllForms = useCallback(
		async (data: IFormTwo) => {
			setIsLoading(true);
			const user = {
				isHCP: true,
				countryId,
				countryCode: countryId,
				referrer: document.referrer,
				preferredLanguage: defaultLanguage == "he" ? "en" : defaultLanguage,
				...{ ...formOneState, ...data }
			};
			await signUpRequest(user);
			setActiveStep(step => step + 1);
			setIsLoading(false);
		},
		[countryId, defaultLanguage, formOneState]
	);

	const checkCRMUserExist = useCallback(async (CRMRequest: CRMUserRequestDto) => {
		setIsLoading(true);
		const response: CRMUserResponseDto = await checkIfCRMUserExists(CRMRequest);
		if (response.crmUserResponse.customer_id) {
			setCRMFirstName(response.crmUserResponse.firstName);
			setCRMLastName(response.crmUserResponse.lastName);
		}
		setActiveStep(step => step + 1);
		setIsLoading(false);
	}, []);

	return (
		<>
			{activeStep === 0 && (
				<FormOne
					setActiveStep={setActiveStep}
					formOneState={formOneState}
					setFormOneState={setFormOneState}
					checkCRMUserExist={checkCRMUserExist}
				/>
			)}
			{activeStep === 1 &&
				countryId?.toLowerCase() != "de" &&
				countryId?.toLowerCase() != "us" &&
				CRMFirstName === "" && (
					<FormTwo
						isLoading={isLoading}
						submitAllForms={submitAllForms}
						activeStep={activeStep}
						setActiveStep={setActiveStep}
					/>
				)}
			{activeStep === 1 && countryId?.toLowerCase() === "de" && CRMFirstName === "" && (
				<FormTwoDE
					isLoading={isLoading}
					submitAllForms={submitAllForms}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			)}
			{activeStep === 1 && countryId?.toLowerCase() === "us" && CRMFirstName === "" && (
				<FormTwoUS
					isLoading={isLoading}
					submitAllForms={submitAllForms}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			)}
			{activeStep === 1 && CRMFirstName != "" && (
				<FormTwoCRM
					isLoading={isLoading}
					submitAllForms={submitAllForms}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					CRMFirstName={CRMFirstName}
					CRMLastName={CRMLastName}
				/>
			)}
			{activeStep === 2 && <EmailVerification email={formOneState.username} />}
		</>
	);
};

export default SignUpHCP;
